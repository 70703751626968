import * as React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
      <div class="max-w-prose mx-auto rounded-md shadow-lg p-4 sm:p-8 bg-white dark:bg-slate-800 ">
        <article itemScope itemType="http://schema.org/Article">
          <header className="mb-4 mx-auto text-center dark:text-white">
            <h1 className="text-c1" itemProp="headline">
              {post.frontmatter.title}
            </h1>
            <p className="mt-2 text-sm font-sans">{post.frontmatter.date}</p>
            <hr className="mt-2" />
          </header>
          <section
            className="prose prose-slate dark:prose-invert mx-auto"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr className="mt-4" />
        </article>
        <nav className="py-4">
          <ul className="max-w-prose mx-auto flex flex-wrap justify-between font-sans text-c1 font-bold">
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
